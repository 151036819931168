import React from "react";
import moment from 'moment';
import MaterialTable from "@material-table/core";
import Header from "./Header";
import { totalSupply } from "./projectInfo";

export const LogsPage = () => {
    const { useState } = React;
    const { useEffect } = React;

    const [loading, setLoadingState] = useState(true);
    const [logs, setLogs] = useState([]);
    const [soldAmount, setSoldAmount] = useState({"tokens": 0, "sol": 0});
    const [boughtAmount, setBoughtAmount] = useState({"tokens": 0, "sol": 0});

    const actionsLookup = {
        "BUY": "Buy",
        "SELL": "Sell"
    }

    const projectsLookup = {
        "popz": "popz",
        "PONKE": "popz"
    }

    useEffect(() => {
        fetchLogs();
    }, [])

    function fetchLogs() {

        const url = 'https://api.meme-admin.site/getLogs';
        const data = { "sk": localStorage.getItem("sk") }
        fetch(url, {
            body: JSON.stringify(data),
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'content-type': 'application/json'
            },
            method: 'POST',
            mode: 'cors',
            redirect: 'follow',
            referrer: 'no-referrer',
        })
            .then(response => response.json())
            .then(result => {
                var _soldTokens = 0;
                var _gotSol = 0;
                var _boughtTokens = 0
                var _spentSol = 0;
                for (var _item of result.result) {
                    _item.date = moment(_item.timestamp * 1000).format();
                    if (_item.action === "BUY") {
                        _boughtTokens += _item.token_amount;
                        _spentSol += _item.base_token_amount;
                    } else {
                        _soldTokens += _item.token_amount;
                        _gotSol += _item.base_token_amount;
                    }
                    _item.supplyPercentage = _item.token_amount / totalSupply * 100;
                }
                setLogs(result.result);
                setSoldAmount({"tokens": _soldTokens, "sol": _gotSol});
                setBoughtAmount({"tokens": _boughtTokens, "sol": _spentSol});
                setLoadingState(false);
            });
    };




    const columns = [
        { title: "User", field: "user", align: "left", editable: "never", filtering: true },
        { title: "Wallet", field: "wallet", align: "left", editable: "never", sorting: false, filtering: true },
        { title: "Action", field: "action", align: "left", editable: "never", lookup: actionsLookup },
        { title: "Token amount", field: "token_amount", align: "left", editable: "never", filtering: true, render: rowData => {
            return <div>{rowData.token_amount?.toFixed(2)}</div>
        } },
        { title: "Supply %", field: "supplyPercentage", align: "left", editable: "never", filtering: true, render: rowData => {
            return <div>{rowData.supplyPercentage?.toFixed(2)}</div>
        } },
        { title: "SOL amount", field: "base_token_amount", align: "left", editable: "never", filtering: true, render: rowData => {
            return <div>{rowData.base_token_amount?.toFixed(2)}</div>
        } },
        { title: "Tx ID", field: "tx_id", align: "left", editable: "never", filtering: true },
        { title: "Project", field: "project", align: "left", editable: "never", filtering: true, lookup: projectsLookup },
        { title: "Timestamp", field: "timestamp", align: "left", editable: "never", filtering: true },
        { title: "Date", field: "date", align: "left", editable: "never", filtering: true },

    ];

    return <div>
        {<Header />}
        {
            loading
                ?
                <div className="container loading-spinner pt-5 mt-5" style={{ width: "10rem", height: "10rem" }}>
                    <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                        viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                        <path fill="#919191" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                            <animateTransform
                                attributeName="transform"
                                attributeType="XML"
                                type="rotate"
                                dur="1s"
                                from="0 50 50"
                                to="360 50 50"
                                repeatCount="indefinite" />
                        </path>
                    </svg>
                </div>
                :
                <div>
                    <div className="container pt-3 pb-3">
                        <div className="container">
                            <div>Sold: <span className="bold">{soldAmount.tokens?.toFixed(0)} ({(soldAmount.tokens / totalSupply * 100)?.toFixed(2)}% of supply) for {soldAmount.sol?.toFixed(2)} SOL</span></div>
                            <div>Bought: <span className="bold">{boughtAmount.tokens?.toFixed(0)} ({(boughtAmount.tokens / totalSupply * 100)?.toFixed(2)}% of supply) for {boughtAmount.sol?.toFixed(2)} SOL</span></div>
                        </div>
                    </div>

                    <MaterialTable
                        columns={columns}
                        data={logs}
                        title=""
                        options={{
                            addRowPosition: "first",
                            search: false,
                            columnsButton: false,
                            filtering: true,
                            pageSize: 50,
                            pageSizeOptions: [10, 20, 50, 100, 150],
                            headerStyle: {
                                fontWeight: "bold"
                            },
                            filterCellStyle: {
                                paddingTop: "0px",
                                paddingBottom: "8px",
                            }
                        }}
                    />
                </div>
        }

    </div>;

} 