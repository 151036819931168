import { SolanaWalletAction, TransactionResult, Wallet } from '../types/types';

import {
  Connection,
  PublicKey,
  Keypair,
  Transaction,
  LAMPORTS_PER_SOL,
  VersionedTransaction,
  TransactionMessage,
  Signer,
  SystemProgram,
} from '@solana/web3.js';
import {
  Liquidity,
  LiquidityPoolKeys,
  jsonInfo2PoolKeys,
  LiquidityPoolJsonInfo,
  TokenAccount,
  Token,
  TokenAmount,
  TOKEN_PROGRAM_ID,
  Percent,
  SPL_ACCOUNT_LAYOUT,
} from '@raydium-io/raydium-sdk';
import * as nacl from "tweetnacl";

import bs58 from 'bs58'
import { getJitoBundleStatus, getRandomTipAccount, sendJitoBundle } from './jitoRpc';

const SOLANA_TOKEN_ADDRESS = 'So11111111111111111111111111111111111111112';

export class SolanaService {
  private static instance: SolanaService;

  private readonly connection: Connection;
  private walletsMap: Map<string, Keypair> | undefined;
  private liquidityPoolKeys: LiquidityPoolKeys | undefined;

  constructor(poolData: LiquidityPoolJsonInfo) {
    // const solanaRPCEnv = "https://mainnet.helius-rpc.com/?api-key=131761fc-9def-4f8f-907f-4de59d141cfd"
    const solanaRPCEnv = "https://wiser-proud-spring.solana-mainnet.quiknode.pro/0617f2012df1bbc7b93dd893a05c5be4e6188c18/"
    const solanaRPC: string = solanaRPCEnv ? solanaRPCEnv : "";

    this.connection = new Connection(solanaRPC, { commitment: 'confirmed' });
    this.liquidityPoolKeys = jsonInfo2PoolKeys(poolData) as LiquidityPoolKeys;

    if (SolanaService.instance) {
      return SolanaService.instance;
    }
    SolanaService.instance = this;
  }

  public setWalletsMap(wallets: Wallet[]) {
    const walletMap: Map<string, Keypair> = new Map<string, Keypair>();
    wallets.forEach((wallet: Wallet) => {
      // const _keypair = Keypair.fromSecretKey(
      //   new Uint8Array(wallet.privateKey.split(',').map(Number)),
      // );
      const _keypair = Keypair.fromSecretKey(bs58.decode(wallet.privateKey));
      walletMap.set(wallet.address, _keypair);
    });
    this.walletsMap = walletMap;
  }

  public async getCurrentMcap() {
    const SOL_PRICE = 130;
    const totalSupply = 1000000000;

    const poolKeys = this.liquidityPoolKeys as LiquidityPoolKeys;
    const poolInfo = await Liquidity.fetchInfo({
      connection: this.connection,
      poolKeys: poolKeys,
    });

    const baseReserve = poolInfo.baseReserve.toNumber() / LAMPORTS_PER_SOL;
    const quoteReserve = poolInfo.quoteReserve.toNumber() / 1000000;
    const tokenPriceBeforePurchase = baseReserve / quoteReserve;
    const currentMarketCap = tokenPriceBeforePurchase * totalSupply * SOL_PRICE;
    return currentMarketCap;
  }

  public async getMMInfoData() {
    const SOL_PRICE = 130;
    const totalSupply = 1000000000;

    const poolKeys = this.liquidityPoolKeys as LiquidityPoolKeys;
    const poolInfo = await Liquidity.fetchInfo({
      connection: this.connection,
      poolKeys: poolKeys,
    });

    const baseReserve = poolInfo.baseReserve.toNumber() / LAMPORTS_PER_SOL;
    const quoteReserve = poolInfo.quoteReserve.toNumber() / 1000000;
    console.log('Base reserve: ', baseReserve, ". Quote reserve: ", quoteReserve);

    var res = []
    for (const val of [10, 20, 30, 40, 60, 80, 100, 120]) {
      const tokenPriceBeforePurchase = baseReserve / quoteReserve;
      const currentMarketCap = tokenPriceBeforePurchase * totalSupply * SOL_PRICE;
      const purchaseAmount = val;  // Amount of sol
      const newBaseReserve = baseReserve + purchaseAmount;
      const newQuoteReserve = baseReserve * quoteReserve / newBaseReserve;
      const newTokenPrice = newBaseReserve / newQuoteReserve * SOL_PRICE;
      const newMarketCap = newTokenPrice * totalSupply;
      res.push({ "solAmount": val, "mcap": newMarketCap });
    }

    return res;
  }

  public async processAction(
    walletAction: SolanaWalletAction,
  ): Promise<any> {
    try {
      const tokenAddress = walletAction.tokenAddress;
      const walletAddress = walletAction.address;
      const actionSide = walletAction.action === 'BUY' ? 'in' : 'out';
      const transactionId = await this.swapOnRaydium(
        walletAddress,
        tokenAddress,
        actionSide,
        Number(walletAction.amount),
      );
      return transactionId;
    } catch (error) {
      console.error(`Solana Error: ${error}`);
      return false;
    }
  }

  private async swapOnRaydium(
    walletAddress: string,
    tokenAddress: string,
    actionSide: 'in' | 'out',
    amount: number,
  ): Promise<any> {
    try {
      if (amount === undefined || Number.isNaN(amount)) {
        console.log('swapOnRaydium() false amount');
        return false;
      }

      const toTokenAddress: string = actionSide === 'in' ? tokenAddress : SOLANA_TOKEN_ADDRESS;
      const transactionResult: TransactionResult = await this.getSwapTransactionJito(
        toTokenAddress,
        amount,
        this.liquidityPoolKeys,
        150000,
        true,
        walletAddress,
      ) as TransactionResult;

      if (transactionResult.success) {
        console.log({
          level: 'info',
          message: `Raydium Swap Complete: https://solscan.io/tx/${transactionResult.transactionId}`,
          context: 'Solana Service',
        });
        return transactionResult.transactionId;
      } else {
        return false;
      }
    } catch (error) {
      console.error(`Solana Error: ${error}`);
      return false;
    }
  }

  public async getTokenBalance(walletAddress: string, tokenAddress: string) {
    try {
      const walletTokenAccount = await this.connection.getParsedTokenAccountsByOwner(
        this.walletsMap?.get(walletAddress)?.publicKey as PublicKey,
        { mint: new PublicKey(tokenAddress) },
      );
      if (walletTokenAccount.value.length === 0) {
        return 0;
      }
      return walletTokenAccount.value[0].account.data.parsed.info.tokenAmount.uiAmount;
    } catch (error) {
      console.error(`Solana Service Error: ${error}`);
    }
  }

  public async getSOLBalance(walletAddress: string) {
    try {
      return await this.connection.getBalance(this.walletsMap?.get(walletAddress)?.publicKey as PublicKey) / LAMPORTS_PER_SOL;
    } catch (error) {
      console.error(`Solana Service Error: ${error}`);
    }
  }

  private async getOwnerTokenAccounts(walletAddress: string) {
    try {
      const walletTokenAccount = await this.connection.getTokenAccountsByOwner(
        this.walletsMap?.get(walletAddress)?.publicKey as PublicKey,
        {
          programId: TOKEN_PROGRAM_ID,
        },
      );

      return walletTokenAccount.value.map((i) => ({
        pubkey: i.pubkey,
        programId: i.account.owner,
        accountInfo: SPL_ACCOUNT_LAYOUT.decode(i.account.data),
      }));
    } catch (error) {
      console.error(`Solana Service Error: ${error}`);
    }
  }


  public async executeActionsBundle(
    walletActions: SolanaWalletAction[],
  ): Promise<any | undefined | null> {
    try {

      if (walletActions.length > 2) {
        throw new Error(`Actions bundle > 2`);
      }

      const wallet1 = this.walletsMap?.get(walletActions[0].address);
      if (!wallet1) {
        throw new Error(`Wallet 1 is not found.`);
      }
      const wallet2 = this.walletsMap?.get(walletActions[1].address);
      if (!wallet2) {
        throw new Error(`Wallet 2 is not found.`);
      }

      const bundleTxArray: VersionedTransaction[] = [];

      // PREPARE FIRST TX ........................................................
      const actionSide = walletActions[0].action === 'BUY' ? 'in' : 'out';
      const toTokenAddress: string = actionSide === 'in' ? walletActions[0].tokenAddress : SOLANA_TOKEN_ADDRESS;
      const directionIn = (this.liquidityPoolKeys?.quoteMint.toString()) === toTokenAddress;
      let calcAmountOutResult = await this.calcAmountOut(
        this.liquidityPoolKeys as LiquidityPoolKeys,
        Number(walletActions[0].amount),
        directionIn,
        1 // 1% of slippage
      );

      const userTokenAccounts = await this.getOwnerTokenAccounts(walletActions[0].address);
      const swapTransaction = await Liquidity.makeSwapInstructionSimple({
        connection: this.connection,
        makeTxVersion: 0,
        poolKeys: {
          ...this.liquidityPoolKeys,
        } as LiquidityPoolKeys,
        userKeys: {
          tokenAccounts: userTokenAccounts as TokenAccount[],
          owner: wallet1?.publicKey as PublicKey,
        },
        amountIn: calcAmountOutResult?.amountIn as TokenAmount,
        amountOut: calcAmountOutResult?.minAmountOut as TokenAmount,
        fixedSide: 'in',
        config: {
          bypassAssociatedCheck: false,
        },
        computeBudgetConfig: {
          units: 1000000,
          microLamports: 1000000,
        },
      });

      const instructions1 = swapTransaction.innerTransactions[0].instructions.filter(Boolean);
      const blockhashResponse1 = await this.connection.getLatestBlockhash();

      const tipIx = SystemProgram.transfer({
        fromPubkey: wallet1?.publicKey as PublicKey,
        toPubkey: getRandomTipAccount(),
        lamports: LAMPORTS_PER_SOL * 0.005, // 0.005 SOL
      });

      instructions1.push(tipIx);

      const versionedTransaction1 = new VersionedTransaction(
        new TransactionMessage({
          payerKey: this.walletsMap?.get(walletActions[0].address)?.publicKey as PublicKey,
          recentBlockhash: blockhashResponse1.blockhash,
          instructions: [...instructions1],
        }).compileToV0Message(),
      );

      versionedTransaction1.sign([this.walletsMap?.get(walletActions[0].address) as Signer]);
      bundleTxArray.push(versionedTransaction1);

      // PREPARE SECOND TX ........................................................

      const actionSide2 = walletActions[1].action === 'BUY' ? 'in' : 'out';
      const toTokenAddress2: string = actionSide2 === 'in' ? walletActions[1].tokenAddress : SOLANA_TOKEN_ADDRESS;
      const directionIn2 = (this.liquidityPoolKeys?.quoteMint.toString()) === toTokenAddress2;
      let calcAmountOutResult2 = await this.calcAmountOut(
        this.liquidityPoolKeys as LiquidityPoolKeys,
        Number(calcAmountOutResult?.amountOut.toExact()),
        directionIn2,
        1 // 1% of slippage
      );

      const swapTransaction2 = await Liquidity.makeSwapInstructionSimple({
        connection: this.connection,
        makeTxVersion: 0,
        poolKeys: {
          ...this.liquidityPoolKeys,
        } as LiquidityPoolKeys,
        userKeys: {
          tokenAccounts: userTokenAccounts as TokenAccount[],
          owner: wallet2?.publicKey as PublicKey,
        },
        // amountIn: calcAmountOutResult2?.amountIn as TokenAmount,
        // amountOut: calcAmountOutResult2?.amountOut as TokenAmount,
        // fixedSide: 'out',
        amountIn: calcAmountOutResult?.amountOut as TokenAmount,
        amountOut: calcAmountOutResult2?.amountOut as TokenAmount,
        fixedSide: 'in',
        config: {
          bypassAssociatedCheck: false,
        },
        computeBudgetConfig: {
          units: 1000000,
          microLamports: 1000000,
        },
      });

      const instructions2 = swapTransaction2.innerTransactions[0].instructions.filter(Boolean);
      const blockhashResponse2 = await this.connection.getLatestBlockhash();

      const versionedTransaction2 = new VersionedTransaction(
        new TransactionMessage({
          payerKey: this.walletsMap?.get(walletActions[1].address)?.publicKey as PublicKey,
          recentBlockhash: blockhashResponse2.blockhash,
          instructions: [...instructions2],
        }).compileToV0Message(),
      );

      versionedTransaction2.sign([this.walletsMap?.get(walletActions[1].address) as Signer]);
      bundleTxArray.push(versionedTransaction2);


      const response = await sendJitoBundle(bundleTxArray);

      const bundleId = response.data.result;

      let result = false;

      for (let i = 0; i < 10; i++) {
        await new Promise((resolve) => setTimeout(resolve, 250));
        let bundleStatus = await getJitoBundleStatus(bundleId);
        if (bundleStatus?.data.result?.value[0]?.confirmation_status === "confirmed") {
          result = true;
          break;
        } else {
          console.log(bundleStatus?.data.result);
        }
      }

      return result;

    } catch (error) {
      console.error(`Solana Service Error: ${error}`);
    }
  }

  private async getSwapTransactionJito(
    toToken: string,
    amount: number,
    poolKeys: LiquidityPoolKeys | undefined,
    maxLamports: number = 1000000,
    useVersionedTransaction = true,
    walletAddress: string,
  ): Promise<TransactionResult | undefined | null> {
    try {
      const sleep = async (ms: number) => {
        return new Promise(r => setTimeout(r, ms));
      };

      const payer = this.walletsMap?.get(walletAddress);

      const directionIn = (poolKeys?.quoteMint.toString()) === toToken;
      const calcAmountOutResult = await this.calcAmountOut(
        poolKeys as LiquidityPoolKeys,
        amount,
        directionIn,
      );
      const userTokenAccounts = await this.getOwnerTokenAccounts(walletAddress);
      const swapTransaction = await Liquidity.makeSwapInstructionSimple({
        connection: this.connection,
        makeTxVersion: useVersionedTransaction ? 0 : 1,
        poolKeys: {
          ...poolKeys,
        } as LiquidityPoolKeys,
        userKeys: {
          tokenAccounts: userTokenAccounts as TokenAccount[],
          owner: payer?.publicKey as PublicKey,
        },
        amountIn: calcAmountOutResult?.amountIn as TokenAmount,
        amountOut: calcAmountOutResult?.minAmountOut as TokenAmount,
        fixedSide: 'in',
        config: {
          bypassAssociatedCheck: false,
        },
        computeBudgetConfig: {
          units: 1000000,
          microLamports: maxLamports,
        },
      });

      const instructions =
        swapTransaction.innerTransactions[0].instructions.filter(Boolean);

      const blockhashResponse = await this.connection.getLatestBlockhash();

      const tipIx = SystemProgram.transfer({
        fromPubkey: payer?.publicKey as PublicKey,
        toPubkey: getRandomTipAccount(),
        lamports: LAMPORTS_PER_SOL * 0.002, // 0.002 SOL
      });

      const versionedTransaction = new VersionedTransaction(
        new TransactionMessage({
          payerKey: this.walletsMap?.get(walletAddress)?.publicKey as PublicKey,
          recentBlockhash: blockhashResponse.blockhash,
          instructions: [...instructions, tipIx],
        }).compileToV0Message(),
      );

      versionedTransaction.sign([this.walletsMap?.get(walletAddress) as Signer]);

      let returnTransactionData: TransactionResult = {
        transaction: versionedTransaction,
        transactionId: "",
        success: false
      };

      let res = await sendJitoBundle([versionedTransaction]);
      const bundleId = res.data.result;
      let bundleStatus;


      while (bundleStatus?.data.result?.value[0]?.confirmation_status !== "confirmed") {
        await sleep(1000);
        bundleStatus = await getJitoBundleStatus(bundleId);
        console.log(bundleStatus?.data.result);
      }

      returnTransactionData.transactionId = bundleStatus.data.result.value[0].transactions[0]
      returnTransactionData.success = true

      return returnTransactionData;
    } catch (error) {
      console.error(`Solana Service Error: ${error}`);
    }
  }

  private async getSwapTransaction(
    toToken: string,
    amount: number,
    poolKeys: LiquidityPoolKeys | undefined,
    maxLamports: number = 1000000,
    useVersionedTransaction = true,
    fixedSide: 'in' | 'out',
    walletAddress: string,
  ): Promise<TransactionResult | undefined | null> {
    try {
      const sleep = async (ms: number) => {
        return new Promise(r => setTimeout(r, ms));
      };

      const payer = this.walletsMap?.get(walletAddress);

      const directionIn = (poolKeys?.quoteMint.toString()) === toToken;
      const calcAmountOutResult = await this.calcAmountOut(
        poolKeys as LiquidityPoolKeys,
        amount,
        directionIn,
      );
      const userTokenAccounts = await this.getOwnerTokenAccounts(walletAddress);
      const swapTransaction = await Liquidity.makeSwapInstructionSimple({
        connection: this.connection,
        makeTxVersion: useVersionedTransaction ? 0 : 1,
        poolKeys: {
          ...poolKeys,
        } as LiquidityPoolKeys,
        userKeys: {
          tokenAccounts: userTokenAccounts as TokenAccount[],
          owner: payer?.publicKey as PublicKey,
        },
        amountIn: calcAmountOutResult?.amountIn as TokenAmount,
        amountOut: calcAmountOutResult?.minAmountOut as TokenAmount,
        fixedSide: fixedSide,
        config: {
          bypassAssociatedCheck: false,
        },
        computeBudgetConfig: {
          units: 1000000,
          microLamports: maxLamports,
        },
      });

      const instructions =
        swapTransaction.innerTransactions[0].instructions.filter(Boolean);

      const blockhashResponse = await this.connection.getLatestBlockhash();

      const transaction = new Transaction(
        {
          feePayer: payer?.publicKey,
          blockhash: blockhashResponse.blockhash,
          lastValidBlockHeight: blockhashResponse.lastValidBlockHeight,
        }
      );

      instructions.forEach((instruction) => {
        transaction.add(instruction)
      })

      const message = transaction.serializeMessage();
      const signature = nacl.sign.detached(message, payer?.secretKey as Uint8Array);
      transaction.addSignature(payer?.publicKey as PublicKey, Buffer.from(signature));
      const rawTransaction = transaction.serialize();

      const transactionId = await this.connection.sendRawTransaction(rawTransaction, {
        skipPreflight: true,
      });

      let returnTransactionData: TransactionResult = {
        transaction: transaction,
        transactionId: transactionId,
        success: false
      };

      await new Promise((resolve, reject) => {
        this.connection.onSignature(
          transactionId,
          (result) => {
            if (result.err) {
              console.error('Transaction failed', result.err);
              reject(result.err);
            } else {
              console.log('Transaction confirmed');
              resolve(true);
            }
          },
          'finalized' // we can use recent or confirmed
        );
      });

      returnTransactionData.success = true;

      return returnTransactionData;
    } catch (error) {
      console.error(`Solana Service Error: ${error}`);
    }
  }

  private async calcAmountOut(
    poolKeys: LiquidityPoolKeys,
    rawAmountIn: number,
    swapInDirection: boolean,
    slippagePercent: number = 20
  ) {
    try {
      const poolInfo = await Liquidity.fetchInfo({
        connection: this.connection,
        poolKeys,
      });

      let currencyInMint = poolKeys.baseMint;
      let currencyInDecimals = poolInfo.baseDecimals;
      let currencyOutMint = poolKeys.quoteMint;
      let currencyOutDecimals = poolInfo.quoteDecimals;
      if (!swapInDirection) {
        currencyInMint = poolKeys.quoteMint;
        currencyInDecimals = poolInfo.quoteDecimals;
        currencyOutMint = poolKeys.baseMint;
        currencyOutDecimals = poolInfo.baseDecimals;
      }

      const currencyIn = new Token(
        TOKEN_PROGRAM_ID,
        currencyInMint,
        currencyInDecimals,
      );
      const amountIn = new TokenAmount(currencyIn, rawAmountIn, false);
      const currencyOut = new Token(
        TOKEN_PROGRAM_ID,
        currencyOutMint,
        currencyOutDecimals,
      );
      const slippage = new Percent(slippagePercent, 100); // 20% slippage

      const {
        amountOut,
        minAmountOut,
        currentPrice,
        executionPrice,
        priceImpact,
        fee,
      } = Liquidity.computeAmountOut({
        poolKeys,
        poolInfo,
        amountIn,
        currencyOut,
        slippage,
      });

      return {
        amountIn,
        amountOut,
        minAmountOut,
        currentPrice,
        executionPrice,
        priceImpact,
        fee,
      };
    } catch (error) {
      console.error(`Solana Service Error: ${error}`);
    }
  }
}
