import { useState } from "react";
import ReactModal from "react-modal";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormLabel from '@material-ui/core/FormLabel';

export const KillLimitsActionModal = ({ setShowModal, showModal, tokenName, tokenAddress, solanaService }) => {
    // tokenName is SOL for BUY action

    const [amount, setAmount] = useState(0);
    const [errorMsg, setErrorMsg] = useState();
    const [loading, setLoadingState] = useState(false);
    const [firstAction, setFirstAction] = useState("BUY");
    const [walletAddress, setWalletAddress] = useState();


    const executeAction = async () => {
        setErrorMsg(null);
        if (!amount) {
            setErrorMsg("Please enter amount");
            return;
        }
        var _amount = parseFloat(amount);
        if (!_amount) {
            setErrorMsg("Incorrect amount");
            return;
        }

        setLoadingState(true);

        if (firstAction === "BUY") {
            const _solBalance = await solanaService.getSOLBalance(walletAddress);
            if (_solBalance < _amount + 0.02) {
                setErrorMsg(`Not enough SOL. Current balance: ${_solBalance}`);
                setLoadingState(false);
                return;
            }
        }

        if (firstAction === "SELL") {
            const _solBalance = await solanaService.getSOLBalance(walletAddress);
            const _tokenBalance = await solanaService.getTokenBalance(walletAddress, tokenAddress);
            if (_tokenBalance < _amount) {
                setErrorMsg(`Not enough tokens. Current balance: ${_tokenBalance}`);
                setLoadingState(false);
                return;
            }
            if (_solBalance < 0.02) {
                setErrorMsg(`Not enough SOL. Current balance: ${_solBalance}`);
                setLoadingState(false);
                return;
            }
        }

        const actions = [
            {
                address: walletAddress,
                action: firstAction === "BUY" ? "BUY" : "SELL",
                tokenAddress: tokenAddress,
                amount: _amount
            },
            {
                address: walletAddress,
                action: firstAction === "BUY" ? "SELL" : "BUY",
                tokenAddress: tokenAddress,
                amount: _amount
            },
        ]

        const result = await solanaService.executeActionsBundle(actions);
        
        if (result) {
            setLoadingState(false);
            setErrorMsg(`DONE!!!`);
        } else {
            setErrorMsg(`FAIL...`);
            setLoadingState(false);
        }
    }

    function closeModal() {
        setErrorMsg(null);
        setLoadingState(false);
        setShowModal(false);
        setWalletAddress(null);
    }


    const handleRadioChange = (event) => {
        setFirstAction(event.target.value);
    };

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    return (

        <ReactModal
            ariaHideApp={false}
            isOpen={showModal}
            contentLabel="Minimal Modal Example"
            style={customStyles}
        >
            <div className="container" style={{ minHeight: "200px", minWidth: "300px" }}>

                <div className="row pt-4">
                    <div className="input-group mb-3">
                        <input placeholder="Enter wallet" id="walletInput" type="text" className="form-control" aria-label="Wallet" onChange={({ target }) => setWalletAddress(target.value)}></input>
                    </div>
                    <div className="input-group mb-3">
                        <input id="tokenAmountInput" placeholder={firstAction === "BUY" ? "Amount SOL" : `Amount ${tokenName}`} type="text" className="form-control" aria-label="Amount" onChange={({ target }) => setAmount(target.value)}></input>
                    </div>
                    <div className="mb-3">
                        <FormLabel component="legend">Direction</FormLabel>
                        <RadioGroup aria-label="firstAction" name="firstAction" value={firstAction} onChange={handleRadioChange}>
                            <FormControlLabel value="BUY" control={<Radio />} label="Buy -> Sell" />
                            <FormControlLabel value="SELL" control={<Radio />} label="Sell -> Buy" />
                        </RadioGroup>
                    </div>
                    {
                        errorMsg ? <div className="mt-2" style={{ color: "red" }}>{errorMsg}</div> : <div></div>
                    }
                </div>

                {loading ?
                    <div className="container text-center mt-1 mb-1">
                        <div className="container loading-spinner" style={{ width: "8rem", height: "8rem" }}>
                            <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                                <path fill="#919191" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                    <animateTransform
                                        attributeName="transform"
                                        attributeType="XML"
                                        type="rotate"
                                        dur="1s"
                                        from="0 50 50"
                                        to="360 50 50"
                                        repeatCount="indefinite" />
                                </path>
                            </svg>
                        </div>
                        <div className="container">Sending TX. Please wait.</div>
                    </div>
                    :
                    <div>
                        <div className="row mt-4">
                            <button type="button" className="btn btn-warning bold" onClick={executeAction}>Execute</button>
                        </div>
                        <div className="row mt-4">
                            <button type="button" className="btn btn-outline-dark bold" onClick={closeModal}>Cancel</button>
                        </div>
                    </div>
                }


            </div>
        </ReactModal>
    )
}

