import { Navigate, Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { WalletsPage } from "./components/WalletsPage";
import { LogsPage } from "./components/LogsPage";
import LoginPage from "./components/LoginPage";
import { InfoPage } from "./components/InfoPage";

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={localStorage.getItem('sk') ? <Navigate replace to="/wallets" /> : <Navigate replace to="/login" />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/wallets" element={<WalletsPage />} />
        <Route path="/logs" element={<LogsPage />} />
        <Route path="/info" element={<InfoPage />} />
      </Routes>
    </Router>
  );
}

export default App;